<template>
    <div class="card-tail self-start relative">
        <p class="border-b pb-2 mb-4">
            Kommentarer
        </p>
        <div v-for="comment in commentsData" class="flex justify-start my-5" :key="comment.id">
            <div class="w-10 h-10 flex justify-center items-center text-white mr-3 bg-green-400 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M12 14l9-5-9-5-9 5 9 5z" />
                    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    />
                </svg>
            </div>
            <div class="flex-col">
                <p class="font-light">{{ comment.individualName }}</p>
                <p v-if="comment.text" class="font-light my-2 text-sm">{{ comment.text }}</p>
                <p class="text-gray-400 text-xs">{{ timeFormater(comment.createdDate) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../invoices.api";

export default {
    data() {
        return {
            commentsData: [],
        };
    },

    created() {
        this.getComments();
    },

    methods: {
        async getComments() {
            this.commentsData = await Api.getCommentsBySourceId(this.$route.params.invoiceId);
        },

        timeFormater(time) {
            return moment(time).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>
